// Styles for Dynamic User Controlled Single Container Background
.custom-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .user_mode & {
        padding-top: 50px;
        @media screen and (max-width: $breakpoint-sm-max){
            padding-top: 30px;
        }
    }
    .custom-background-image {
        .edit_mode & {
              &:after {
                content:'Set Background Image';
                display: block;
                font-size: 16px;
                font-family: $font-secondary;
                font-weight: 600;
                background-color: rgba(white, 0.4);
                color: $color-primary;
                border: 1px dashed #DDA301;
                text-align: center;
              }
        }
        .heroPhotoElement {
            margin-top: 0;
            margin-bottom: 0;
            img {
                .user_mode & {
                    display: none;
                }
            }
        }
    }
}