// Site Search (Desktop)
$nav-title-bar-height: 40px !default;
$nav-title-bar-height-fixed: .01px !default;
$nav-title-bar-band-background-color: seashell !default;
$nav-title-bar-text-color: $gray-dk !default;
$nav-title-bar-font-family: null !default;
$nav-title-bar-font-size: 14px !default;
$nav-title-bar-line-height: 1 !default;
$nav-title-bar-text-case: uppercase !default;
$nav-title-bar-text-weight: 700 !default;

// External Variables
// $content-area-width
// $transition-linear
// $site-logo-offset
// $site-logo-offset-fixed


// Basic Structure
.theme-search-wrapper{ // Hides Title Bar while navigation is loading
  display: none;
  .has-mobile-nav &,
  .has-main-nav &{
    display: block;
  }
}
.has-main-nav{
  .theme-search-wrapper{
    background-color: $nav-title-bar-band-background-color;
    border-bottom: 5px solid $color-secondary;
    .theme-search-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $nav-title-bar-height;
      transition: height $transition-linear;
      @include content-area-width;
      @include content-container-padding;
    }
  }
  .nav-fixed .theme-search-wrapper {
    border-bottom: none;
  }
  .site-tagline-text{
    color: $nav-title-bar-text-color;
    font-family: $nav-title-bar-font-family;
    font-size: $nav-title-bar-font-size;
    text-transform: $nav-title-bar-text-case;
    font-weight: $nav-title-bar-text-weight;
    display: inline-block;
    line-height: $nav-title-bar-line-height;
    padding-left: 20px;
  }
}

// Basic Modifiers
.has-main-nav{
  .nav-fixed .theme-search-wrapper .theme-search-bar{
    height: $nav-title-bar-height-fixed;
  }
  .has-site-logo .theme-search-wrapper .theme-search-bar{
    padding-left: $site-logo-offset;
    transition: height $transition-linear, padding $transition-linear;
  }
  .has-site-logo.nav-fixed .theme-search-wrapper .theme-search-bar{
    padding-left: $site-logo-offset-fixed;
  }
}

// Global Title Nav
// Edit mode UI
.custom-title-bar-extra{
  @include edit_ui_element($padding: -5px);
  &:empty{ display: none; }
}

.user_mode {
  .club-hashtag {
    .textBlockElement {
      margin-top: 0;
      margin-bottom: 0;
      p {
        font-size: 18px;
        font-weight: normal;
        text-transform: none;
        color: $color-secondary;
      }
    }
  }
}

.custom-title-bar-extra {
  .header-sponsor {
    @media screen and (max-width: 1024px) {
      display: none;
    }
    .heroPhotoElement {
      display: flex;
      a {
        order: 2;
        img {
          max-width: 50px;
        }
      }
      .cutline {
        background: none;
        color: #fff;
        font-style: normal;
        text-align: right;
        order: 1;
        max-width: 185px;
        margin: auto;
        padding-left: 0;
        padding-right: 20px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}