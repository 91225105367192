/* News Slideshow Styles */
// 1. Global Styling
// 2. Thumbnails
// 3. Controls
// 4. Aspect Ratio Adjustments
// 5. Accomidations for Small Columns & Mobile Views

$control-height: 2.5rem;

// 1. Global Styling
.drag .newsSlideShow { cursor: pointer; }

.newsSlideShow-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.newsSlideShow-article {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - #{$control-height});
  background-color: $color-white;
  &.current {
    z-index: 1;
  }
  img { position: absolute; }
}

.newsSlideShow-headline {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: calc(100% - 200px);
  margin: 0;
  margin-bottom: 35px;
  text-align: center;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid $color-secondary;
  @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
    width: calc(100% - 100px);
  }
  @media screen and (max-width: $breakpoint-sm-max){
    width: calc(100% - 20px);
    margin-bottom: 5%;
    padding: 5px;
  }
  h1 {
    position: relative;
    display: block;
    padding-top: 20px;
    background-color: $color-white;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
      padding-left: 15px;
    }
    &:after {
      content: "";
      clear: both;
    }
    a {
      display: block;
      width: 100%;
      color: $color-primary;
      font-family: $font-primary;
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      @media screen and (max-width: $breakpoint-sm-max){
        font-size: 18px;
        line-height: 22px;
      }
      // Sadly, `line-clamp` doesn't work in non-webkit browsers, so we need
      // to restrict it with this hack. `white-space: nowrap` serves as a
      // fallback for other browsers with one line of text instead of two.
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;

        .col-md-3 & {
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  p {
    @extend %clearfix;
    display: inline-block;
    text-align: center;
    line-height: 1.5em;
    font-size: 16px;
    font-family: $font-primary;
    margin-bottom: 0;
    width: 100%;
    @media screen and (max-width: $breakpoint-sm-max){
      display: none;
    }
    span {
      display: block;
      width: auto;
      padding-right: 10px;
      padding-left: 10px;
      color: $color-gray-dk;
      background-color: $color-white;
      &:empty { display:none; }
    }
    [class*="button-"]{
      color: $color-secondary;
      background-color: $color-white;
      font-size: 14px;
      display: flex;
      justify-content: center;
      margin: 0;
      text-transform: none;
      transition: color $transition-linear;
      &:after {
        content: '\f105';
        font-family: 'FontAwesome';
        color: $color-secondary;
        font-size: 14px;
        margin-left: 1.5ch;
        transition: color $transition-linear;
      }
      &:hover {
        background: $color-white;
        color: $color-primary;
        &:after {
          color: $color-primary;
        }
      }
    }
  }
}

// 2. Thumbnails
.newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 8%;
  list-style-type: none;
  text-align: center;
  cursor: default;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1024px){
    height: 35px;
  }
  @media screen and (max-width: $breakpoint-sm-max){
    display: none;
  }
  .col-md-3 & {
    font-size: .5em;
  }
  @media only screen and (max-width: 450px) {
    font-size: .5em;
  }
  li {
    display: inline-block;
  }
  a {
    display: block;
    padding: 4px;
    .col-md-3 & {
      padding: 6px;
    }
    @media only screen and (max-width: 450px) {
      padding: 6px;
    }
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: $color-white;
      transition: background-color .1s ease-in-out;
    }
    &:hover,
    &.current {
      &:before {
        background-color: $color-secondary;
      }
    }
  }
  img {
    display: none;
    width: 100%;
  }
}

// 3. Controls
.newsSlideShow-controls {}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
  position: absolute;
  z-index: 10;
  bottom: 50%;
  overflow: hidden;
  width: $control-height - .25;
  height: $control-height - .25;
  padding: 0;
  background-color: $color-secondary;
  font-size: 22px;
  text-align: center;
  color: $color-white;
  transition: transform $transition-linear;
  @media screen and (max-width: $breakpoint-sm-max){
    font-size: 12px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    &:before {
      transform: scale(1.25);
    }
  }
  &:before {
    line-height: 1.65;
    content: '\f104';
    display: block;
    color: inherit;
    font-family: 'fontawesome';
    transition: transform $transition-linear;
  }
}

.newsSlideShow-prev.pill-small-left {
  left: 0;
}

.newsSlideShow-next.pill-small-right {
  right: 0;
}

.newsSlideShow-next.pill-small-right:before {
  content: '\f105';
}

.newsSlideShow-empty {
  text-align: center;
  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

.newsSlideShow-stop,
.newsSlideShow-play { display: none !important; }

// 4. Aspect Ratio Adjustments
.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 { height: initial !important; }

.aspect_ratio_original { @include aspect-ratio(16,9); }
.aspect_ratio_1_1 { @include aspect-ratio(1,1); }
.aspect_ratio_4_3 { @include aspect-ratio(4,3); }
.aspect_ratio_16_9 { @include aspect-ratio(16,9); }
.aspect_ratio_2_1 { @include aspect-ratio(2,1); }
.aspect_ratio_3_1 { @include aspect-ratio(3,1); }
.aspect_ratio_4_1 { @include aspect-ratio(4,1); }
.aspect_ratio_8_5 { @include aspect-ratio(8,5); }

// 5. Accommodations for Small Columns & Mobile Views
@media only screen and (min-width: 767px) {
  .col-md-3,
  .col-md-4 {
    .newsSlideShow-headline {
      h1 a {
        font-size: rem(18);
      }
      p {
        display: none;
      }
    }
  }
  .col-md-3,
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    .aspect_ratio_4_1,
    .aspect_ratio_3_1,
    .aspect_ratio_2_1 {
      .newsSlideShow-headline {
        padding-bottom: 2em;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .newsSlideShow-headline {
    .aspect_ratio_4_1 &,
    .aspect_ratio_3_1 &,
    .aspect_ratio_2_1 & {
        padding-bottom: 2em;
    }
    h1 a {
      font-size: rem(18);
    }
    p {
      display: none;
    }
  }
}
@media only screen and (max-width: 350px) {
  .newsSlideShow-headline {
    p {
      display: none;
    }
  }
}
