// Page Content Container
// This component controls the background behind the site's main content.

$content-container-margin: 30px !default;


.siteContainer {
  min-height: calc(100vh - #{$desktop-nav-height + $account-nav-height-desktop});
  padding: $content-container-margin 0;
  @media screen and (max-width: $breakpoint-sm-max){
    padding: 25px 0;
  }
  @if $enable-full-width-layout != true {
    @include content-area-width;
    @include content-area-container-style;
  }
}
@if $enable-full-width-layout == true {
  #panelTwo{
    &:before{
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      @include content-area-width;
      @include content-area-container-style;
    }
  }
  @if $include-page-title-in-content-area-background == true {
    #yieldContent{
      position: relative;
      @media screen and (max-width: $breakpoint-sm-max){
        margin-right: 15px;
        margin-left: 15px;
      }
      .sportsPage &{ padding-bottom: 20px; }
    }
    #panelTwo{
      .user_mode &{ @include content-container-padding-vert; }
      .sportsPage &{ padding: 0; }
    }
  } @else {
    #panelTwo{
      position: relative;
      .user_mode & { @include content-container-padding-vert; }
    }
    .sportsPage{
      #yieldContent{
        position: relative;
        @include content-container-padding-vert(2);
      }
      #panelTwo{
        position: unset;
        padding: 0;
      }
    }
  }
}

.home {
  .siteContainer {
    padding: 0;
    #yieldContent {
      @media screen and (max-width: $breakpoint-sm-max){
        margin-right: 0;
        margin-left: 0;
      }
    }
    #panelTwo {
      padding-top: 0;
      padding-bottom: 0;
      &:before {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.home.edit_mode {
  #panelTwo {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
