/* Call to Action Element */
// 1. Default Styles
// 2. Variations
// 3. Edge Case Fixes
// 4. Fix for tall text in short container
// 5. Adjustments for Small Columns & Mobile Views
// 6. Accomidation for touch screens

// 1. Default Styles
.sn-call-to-action,
.sn-call-to-action * {
  box-sizing: border-box;
}
.sn-call-to-action {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display:block;
    width: 100%;
    border-top: 5px solid $color-secondary;
    z-index: 10;
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
  .sn-call-to-action-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    text-align: center;
  }
  .sn-call-to-action-overlay-inner {
    display: table;
    width: 100%;
    height: 100%;
  }
  .sn-call-to-action-overlay-text {
    display: table-cell;
    font-size: rem(10);
    height: 100%;
    line-height: 1;
    vertical-align: middle;
  }
  .sn-call-to-action-title {
    color: $color-white;
    font-family: $font-primary;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0.3em;
    @media screen and (max-width: 1024px){
      font-size: 18px;
    }
  }
  .sn-call-to-action-subtitle {
    color: $color-white;
    font-family: $font-secondary;
    font-size: rem(16);
    font-weight: 400;
    line-height: 130%;
    margin-bottom:0;
    @media screen and (max-width: 1024px){
      font-size: 14px;
    }
    a,
    span {
      margin: 0 .25em;
      color: $color-white;
      display: inline-block;
    }
  }
}
.sn-call-to-action .sn-call-to-action-subtitle:first-of-type > * {
  padding-top: 5px;
}

// 2. Variations

/* Slide Up */
.sn-call-to-action.sn-variation-slide-up {
  .sn-call-to-action-overlay {
    background: rgba(39, 43, 48, 0.75);
    top: auto;
    height: 0;
    min-height: 72px !important;
    transition: height 0.5s ease;
    @media screen and (max-width: 1024px){
      height: 100%;
    }
  }
  .sn-call-to-action-subtitle {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  &:hover {
    .sn-call-to-action-overlay {
      height: 100%;
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
    }
  }
}

/* Zoom */
.sn-call-to-action.sn-variation-zoom {
  img {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

/* Text Zoom */
.sn-call-to-action.sn-variation-text-zoom {
  .sn-call-to-action-overlay-text {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    .sn-call-to-action-overlay-text {
      transform: scale(1.1);
    }
  }
}

/* Border */
.sn-call-to-action.sn-variation-border {
  .sn-call-to-action-overlay {
    box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent;
    transition: box-shadow 0.5s ease-in;
  }
  &:hover {
    .sn-call-to-action-overlay {
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
}

// 3. Edge Case Fixes

/* mirrored CTA preview img alignment fixes */
.pe25 .elementPreview img,
.pe33 .elementPreview img {
  width: 100%;
  margin: 0px;
}

// 4. Fix for tall text in short container
.sn-call-to-action.sn-variation-tall-text {
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: initial;
    min-width: 100%;
    transform: translate(-50%, -50%);
  }
}
.sn-call-to-action.sn-variation-zoom.sn-variation-tall-text {
  &:hover {
    img {
      transform: scale(1.2) translate(-40%, -40%);
    }
  }
}

// 5. Adjustments for Small Columns & Mobile Views

/* paragraph spacing */
.pe25 .sn-call-to-action-overlay-text p {
  margin-bottom: .15em;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .col-md-4 .sn-call-to-action, .col-md-3 .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}
@media only screen and (max-width: 350px) {
  .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}

// 6. Accomodation for touch screens
.touch-screen {
  .sn-call-to-action.sn-variation-slide-up,
  .sn-call-to-action.sn-variation-slide-up:focus,
  .sn-call-to-action.sn-variation-slide-up:hover{
    .sn-call-to-action-overlay {
      height: 100%;
      transition: none;
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
      transition: none;
    }
  }
  .sn-call-to-action.sn-variation-border,
  .sn-call-to-action.sn-variation-border:focus,
  .sn-call-to-action.sn-variation-border:hover{
    .sn-call-to-action-overlay{
      transition: none;
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
  .sn-call-to-action.sn-variation-text-zoom,
  .sn-call-to-action.sn-variation-text-zoom:focus,
  .sn-call-to-action.sn-variation-text-zoom:hover{
    .sn-call-to-action-overlay-text{
      transition: none;
      transform: scale(1);
    }
  }
}

// 7. Custom Call To Actions
.user_mode {
  .custom-cta {
    .pageElement {
      @media screen and (max-width: $breakpoint-sm-max){
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .sn-call-to-action {
        padding: 0;
        position: inherit;
        &:hover {
          .sn-call-to-action-overlay {
            .sn-call-to-action-overlay-inner {
              .sn-call-to-action-overlay-text {
                .sn-call-to-action-title {
                  color: $color-secondary-hover;
                }
              }
            }
          }
        }
        &:before,
        &:after {
          display: none;
        }
        .sn-call-to-action-overlay{
          display: flex;
          align-items: flex-end;
          text-align: left;
          padding-top: 30px;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 0;
          @media screen and (max-width: $breakpoint-sm-max){
            padding-left: 20px;
            padding-right: 20px;
          }
          .sn-call-to-action-overlay-inner {
            height: auto;
            transform: translateY(5px);
            border-bottom: 5px solid $color-secondary;
            @media screen and (max-width: $breakpoint-sm-max) {
              transform: translateY(-10px);
            }
            .sn-call-to-action-overlay-text{
              padding: 20px;
              border: 1px solid $color-gray-md;
              background-color: $color-white;
              height: auto;
              @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
                padding: 10px;
              }
              @media screen and (max-width: $breakpoint-sm-max){
                padding: 15px;
              }
              .sn-call-to-action-title {
                color: $color-primary;
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                transition: color $transition-linear;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  display: block;
                  text-align: left;
                }
                @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
                  font-size: 14px;
                }
                @media screen and (max-width: $breakpoint-sm-max){
                  font-size: 16px;
                }
                &:after {
                  content: '\f105';
                  font-family: 'FontAwesome';
                  color: $color-secondary;
                  font-size: 20px;
                  display: block;
                  float: right;
                  line-height: 2.2;
                  @media screen and (max-width: $breakpoint-sm-max) {
                    transform: none;
                    font-size: 18px;
                    line-height: 2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

