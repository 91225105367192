// CORE TABLES

@mixin table {
  border-spacing: 0; // Reset
  border-collapse: initial; // Reset
  margin-top: $core-table-cell-border-width; // This is needed for the responsive table wrap, I believe...
  width: 100%;
  td{
    background-clip: padding-box !important; // Not sure what this is for, will need to refactor this elsewhere, or out completely
    border-style: solid;
    border-color: $core-table-cell-border-color;
    border-width: $core-table-cell-border;
  }
  td:first-child {
    border-left-width: $core-table-border-left-width;
  }
  td:last-child {
    border-right-width: $core-table-border-right-width;
  }
  tr:first-child td {
    border-top-width: $core-table-border-top-width;
  }
  tr:last-child td {
    border-bottom-width: $core-table-border-bottom-width;
  }
}
@mixin table-td {
  height: $core-table-body-min-height;
  padding: $core-table-cell-padding-vertical $core-table-cell-padding-horizontal;
}
@mixin table-td-text{
  font-family: $core-table-body-font-family;
  font-size: $core-table-body-font-size;
  line-height: $core-table-body-line-height;
  font-weight: $core-table-body-font-weight;
  color: $core-table-body-font-color;
  text-align: center;
}
@mixin table-th {
  height: $core-table-header-min-height;
  padding: $core-table-header-padding-vertical $core-table-header-padding-horizontal;
  background-color: $core-table-header-background-color;
  white-space: nowrap;
  border-right: 1px solid $color-white;
}
@mixin table-th-text {
  font-family: $core-table-header-font-family;
  font-size: $core-table-header-font-size;
  line-height: $core-table-header-line-height;
  font-weight: $core-table-header-font-weight;
  color: $core-table-header-font-color;
}
@mixin table-th-highlight {
  // &.ASC,
  // &.DESC,
  // &.highlighted,
  // &.sorttable_sorted,
  // &.sorttable_sorted_reverse {
  &[class*="sorttable"] {
    background-color: $core-table-header-hightlight-color;
  }
}
@mixin table-td-backgrounds {
  tr:nth-child(odd) td{
    background-color: $core-table-body-background-color-odd;
    &.highlight {
      background-color: $core-table-body-hightlight-color-odd;
    }
  }
  tr:nth-child(even) td{
    background-color: $core-table-body-background-color-even;
    &.highlight {
      background-color: $core-table-body-hightlight-color-even;
    }
  }
}
@mixin table-th-sortable {
  &.ajax_sort {
    padding-right: $core-table-sort-icon-offset;
    position: relative;
    cursor: pointer;
    transition: color $transition-linear;
    &:hover {
      color: $core-table-sort-hover-color;
    }
    &:after {
      content: $core-table-sort-icon-default;
      min-width: $core-table-sort-icon-offset;
      font-family: FontAwesome;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.ASC,
    &.sorttable_sorted {
      &:after {
        content: $core-table-sort-icon-up;
      }
    }
    &.DESC,
    &.sorttable_sorted_reverse {
      &:after {
        content: $core-table-sort-icon-down;
      }
    }
    .sorted_img {
      display: none;
    }
  }
}


.dataTable,
.statTable,
.stat_table {
  @include table;
  @include table-td-backgrounds; // might need to scope this to tbody, but it should be fine
  th {
    @include table-th;
    @include table-th-text;
    @include table-th-highlight;
  }
  td {
    @include table-td;
    @include table-td-text;
  }
}

.statTable,
.stat_Table {
  th {
    height: 30px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 12px;
    padding-left: 12px;
  }
  td {
    height: 30px;
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 12px;
    padding-left: 12px;
    .teamName {
      font-weight: 700;
    }
  }
}

// Data Tables
.dataTable {
  th {
    @include table-th-sortable;
  }
}
