$utility-padding-desktop: 40px !default;
$utility-padding-mobile: 20px !default;
$utility-padding-large-desktop: 80px !default;
$utility-padding-large-mobile: 40px !default;
$utility-padding-small-desktop: 20px !default;
$utility-padding-small-mobile: 10px !default;

@mixin utility-padding($desktop, $mobile, $size: null){
  @if ($size !=null) { $size: '-'+$size; }
  .padding#{$size}{
    padding-top: $desktop;
    padding-bottom: $desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $mobile;
      padding-bottom: $mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max) {
        padding-top: $mobile;
        padding-bottom: $mobile;
      }
    }
    &-top {
      padding-top: $desktop;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-top: $mobile;
      }
      &-mobile {
        @media screen and (max-width: $breakpoint-sm-max){
          padding-top: $mobile;
        }
      }
    }
    &-bottom {
      padding-bottom: $desktop;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-bottom: $mobile;
      }
      &-mobile {
        @media screen and (max-width: $breakpoint-sm-max){
          padding-bottom: $mobile;
        }
      }
    }
  }
}

// Apply class to: Layout Containers
// Adds padding top/bottom to layout containers
@include utility-padding($utility-padding-desktop, $utility-padding-mobile);
@include utility-padding($utility-padding-large-desktop, $utility-padding-large-mobile, 'large');
@include utility-padding($utility-padding-small-desktop, $utility-padding-small-mobile, 'small');

// Apply class to: Layout Containers
// Removes padding from columns
.padding-collapse-x{
  &.layoutContainer,
  > .column{
    padding-left: 0;
    padding-right: 0;
  }
}
.padding-collapse-y{
  &.layoutContainer,
  > .column{
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Apply class to: Layout Containers or Page Elements
// Removes margin top/bottom from element(s)
.compact {
  &.pageEl .pageElement,
  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }
  &-top {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-top: 0;
    }
  }
  &-bottom {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-bottom: 0;
    }
  }
}

// Apply class to: Layout Containers or Page Elements
// Hides content on mobile/desktop
.user_mode{
  .hidden-on-mobile{
    @media screen and (max-width: $breakpoint-sm-max){
      display: none;
    }
  }
  .hidden-on-desktop{
    @media screen and (min-width: $breakpoint-md-min) {
      display: none;
    }
  }
}

// Apply class to: Page Elements
// Custom link styling
.custom-link {
  h4 {
    a {
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 600;
      justify-content: space-between;
      min-height: 50px;
      padding: 0;
      padding-left: 20px;
      @media screen and (max-width: 1024px){
        font-size: 16px;
      }
      &:after {
        background-color: $color-secondary;
        color: #fff;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        float: right;
        width: 30px;
        height: 50px;
        padding-right: 1.75ch;
      }
    }
  }
}

// Home Page Box-Shadow Styles
.user_mode {
  // Top layout container box-shadow
  .top-layout-container {
    box-shadow: -1px 8px 9px 5px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    padding-top: 20px;
    @media screen and (max-width: $breakpoint-sm-max){
      margin-top: 30px;
      margin-right: 15px;
      margin-left: 15px;
      width: auto;
    }
  }
  
  // Middle layout container box-shadow
  .middle-layout-container {
    box-shadow: 0px 12px 6px 2px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $breakpoint-sm-max){
      margin-right: 15px;
      margin-left: 15px;
      width: auto;
    }
  }
  
  // Bottom layout container box-shadow
  .bottom-layout-container {
    box-shadow: 1px 6px 9px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    padding-bottom: 20px;
    @media screen and (max-width: $breakpoint-sm-max){
      margin-bottom: 30px;
      margin-right: 15px;
      margin-left: 15px;
      width: auto;
    }
  }
}
