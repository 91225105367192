// Global Footer
// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
}

// Global Sponsor Slider
.global-slider {
  @include edit_ui_element($padding: 10px);
  .edit_mode & {
    max-width: $content-area-width;
    .columnBounds { margin: 10px 0 !important; }
  }
  .slick-prev {
    left: 0;
    &:before {
      content: '\f104';
      font-family: 'FontAwesome';
      color: $color-gray-md;
      opacity: 1;
      font-size: 36px;
    }
  }
  .slick-next {
    right: 0;
    &:before {
      content: '\f105';
      font-family: 'FontAwesome';
      color: $color-gray-md;
      opacity: 1;
      font-size: 36px;
    }
  }
  .slick-list {
    max-width: $content-area-width;
    margin: auto;
    &:hover {
      .slick-track{
        .slick-slide{
          .heroPhotoElement {
            img {
              filter: grayscale(1);
              &:hover {
                filter: grayscale(0);
              }
            }
          }
        }
      }
    }
    .slick-track {
      display: flex;
      align-items: center;
      .slick-slide {
        .heroPhotoElement {
          img {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 0;
            padding-bottom: 0;
            filter: grayscale(0);
            transition: filter $transition-linear;
            @media screen and (max-width: 1024px){
              padding-right: 60px;
              padding-left: 60px;
            }
          }
        }
      }
    }
  }
}

// Custom Styling
.custom-footer-extra {
  display: flex;
  justify-content: center;
  background-color: $color-primary;
  padding-top: 75px;
  padding-bottom: 120px;
  @media screen and (max-width: 1024px){
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .footer-logo-social {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 50px;
    padding-right: 50px;
    min-width: 150px;
    .footer-logo {
      img {
        max-width: 150px;
      }
      .sn-social-media-list {
        margin-top: 0;
        margin-bottom: 0;
        @media screen and (max-width: 1024px) {
          text-align: center;
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .sn-social-media-icon {
          border-radius: 0;
          background-color: $color-secondary;
          font-size: 25px;
          &:hover {
            background-color: $color-secondary-hover;
          }
        }
      }
    }
  }
  .footer-column {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 50px;
    padding-right: 50px;
    min-width: 150px;
    .textBlockElement {
      h3 {
        color: $color-white;
        font-size: 18px;
        font-weight: 600;
        font-family: $font-primary;
        word-break: keep-all;
        margin-bottom: 15px;
        @media screen and (max-width: 1024px) {
          text-align: center !important;
        }
        span {
          &:after {
            display: none;
          }
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        font-family: $font-secondary;
        color: $color-white;
        margin-bottom: 10px;
        word-break: keep-all;
        @media screen and (max-width: 1024px) {
          text-align: center;
        }
        a {
          color: $color-white;
          transition: color $transition-linear;
          font-weight: 400;
          &:hover {
            color: $color-secondary-hover;
            text-decoration: none;
          }
        }
      }
    }
  }
}
