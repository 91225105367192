.user_mode {
    .accordion-header {
      padding: 0;
      &.active {
          .pageElement {
            h3 {
                background-color: $color-secondary;
                color: $color-white;
                &:before {
                    content: '-';
                    text-align: center;
                    width: 20px;
                    position: absolute;
                    right: 30px;
                  }
            }
          }
      }
        .pageElement {
          margin-top: 0;
          margin-bottom: 0;
          h3 {
            font-size: 18px;
            font-family: $font-primary;
            font-weight: 600;
            text-transform: none;
            background-color: $color-gray-lt;
            color: $color-primary;
            border-bottom: 1px solid #fff;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 25px;
            padding-right: 25px;
            cursor: pointer;
            transition: background-color $transition-linear, color $transition-linear;
            @media screen and (max-width: $breakpoint-sm-max) {
                font-size: 14px;
            }
            &:before {
              content: '+';
              text-align: center;
              width: 20px;
              position: absolute;
              right: 30px;
            }
            span {
                &:after {
                    display: none;
                }
            }
          }
        }
    }
    .accordion-content-container {
      background: #fff;
      max-height: 0;
      max-width: $content-area-width;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      transition: max-height .5s ease-in-out, padding .5s ease-in-out;
      .accordion-content {
        margin: 0;
        .textBlockElement {
            p {
                color: $color-gray-dk;
            }
        }
      }
      &.open {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
        max-height: 4000px;
      }
    }
  }